import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import CtaButton from '../cta-button';
import './style.css';

const HeroNew = (props) => (
  <>
    <Row
      className={`heronew-container heronew-container-${props.layout}`}
      id={!props.id ? 'hero' : props.id}
      style={{
        backgroundImage: `url(${props.defaultImage})`,
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : '#FFEBF3',
      }}
    >
      <Col className={`heronew-${props.layout}`}>
        {props.stars ? (
          <div id='starsHappyMothers'>
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} /> 1,000,000+ Happy Mothers
          </div>
        ) : null}
        {!props.title || (props.title && props.useH2) ? null : (
          <h1>{props.title}</h1>
        )}
        {props.title && props.useH2 ? <h2>{props.title}</h2> : null}
        {!props.subheading ? null : <p>{props.subheading}</p>}
        {!props.description ? null : <p>{props.description}</p>}
        {!props.buttonText || !props.buttonText.length ? null : (
          <CtaButton
            onClick={props.handleScrollToElement}
            buttonText={props.buttonText}
            blackButton
            disabled={props.disabled}
          />
        )}
        {props.buttonNote}
        {!props.secondButtonText || !props.secondButtonText.length ? null : (
          <Button
            onClick={props.handleSecondHeroButtonClick}
            disabled={props.disabled}
            className='secondButton'
          >
            {props.secondButtonText}
          </Button>
        )}
      </Col>
    </Row>
  </>
);

export default HeroNew;

import Loadable from 'react-loadable';
import LoadingLight from './loadingLight';

const lazyLoad = (component, loader) =>
  Loadable({
    loader: component,
    loading: loader || LoadingLight,
    delay: 0, // Default is 200ms
  });

const SignInOrSignupForm = lazyLoad(() => import('./signin-or-signup-form'));
const AddressInput = lazyLoad(() => import('./address-input'));
const BitsyModal = lazyLoad(() => import('./bitsy-modal'));
const BitsyModalGiveaway = lazyLoad(() => import('./bitsy-modal-giveaway'));
const SignUpForm = lazyLoad(() => import('./signup-form'));
const SignInForm = lazyLoad(() => import('./signin-form'));
const CouponAndGiftCardForm = lazyLoad(() =>
  import('./coupon-and-gift-card-form'),
);
const AsSeenIn = lazyLoad(() => import('./asseenin'));
const GetTheBox = lazyLoad(() => import('./get-the-box'));
const Cart = lazyLoad(() => import('./review'));
const Testimonials = lazyLoad(() => import('./testimonials'));
const HomepageManifesto = lazyLoad(() => import('./homepage-manifesto'));
const HowItWorks = lazyLoad(() => import('./how-it-works'));
const SocialProof = lazyLoad(() => import('./social-proof'));
const WteFooter = lazyLoad(() => import('./wte-footer'));
const PnmagFooter = lazyLoad(() => import('./pnmag-footer'));
const Footer = lazyLoad(() => import('./footer'));
const AdminNav = lazyLoad(() => import('./admin-nav'));
const CompanyNav = lazyLoad(() => import('./company-nav'));
const PartnershipNavigation = lazyLoad(() =>
  import('./partnership-navigation'),
);
const Navigation = lazyLoad(() => import('./navigation'));
const CancelSurvey = lazyLoad(() => import('./cancel-survey'));
const PastSubs = lazyLoad(() => import('./past-subs'));
const Guarantee = lazyLoad(() => import('./guarantee'));
const SelectSubscriptionBoxes = lazyLoad(() =>
  import('./select-subscription-boxes'),
);
const SubscriptionDetailsForm = lazyLoad(() =>
  import('./subscription-details-form'),
);
const ForgotPasswordForm = lazyLoad(() => import('./forgot-password-form'));
const ResetPasswordForm = lazyLoad(() => import('./reset-password-form'));
const CorporateGiftForm = lazyLoad(() => import('./corporate-gift-form'));
const ContactForm = lazyLoad(() => import('./contact-form'));
const PartnershipsForm = lazyLoad(() => import('./partnerships-form'));
const VerticalTimeline = lazyLoad(() => import('./vertical-timeline'));
const SearchForm = lazyLoad(() => import('./search-form'));
const ShopPageMenu = lazyLoad(() => import('./shop-page-menu'));
const BitsyMobileMenu = lazyLoad(() => import('./mobile-menu-bitsy'));
const BumpMenu = lazyLoad(() => import('./shop-menu-bump'));
const RegistryMenu = lazyLoad(() => import('./registry-menu'));
const BitsyMenu = lazyLoad(() => import('./shop-menu-bitsy'));
const UpdateCCForm = lazyLoad(() => import('./update-cc-form'));
const TopBanner = lazyLoad(() => import('./top-banner'));
const CartTotal = lazyLoad(() => import('./cart-total'));
const Trimesters = lazyLoad(() => import('./trimesters'));
const FAQAccordion = lazyLoad(() => import('./faq-accordion'));
const BreastPumpFooter = lazyLoad(() =>
  import('../containers/breast-pump/breast-pump-footer'),
);
const ReferralSharing = lazyLoad(() => import('./referral-sharing'));
const ReferralSharingCopyLink = lazyLoad(() =>
  import('./referralShareCopyLink'),
);
const ConfirmPasswordForm = lazyLoad(() => import('./confirm-password-form'));
const PregnancyAnnouncementIdeas = lazyLoad(() =>
  import('./pregnancy-announcement-ideas'),
);
const TypeaheadToggleButton = lazyLoad(() =>
  import('./typeahead-toggle-button'),
);
const InsuranceCardUpload = lazyLoad(() => import('./insurance-card-upload'));

export {
  SignInOrSignupForm,
  AddressInput,
  BitsyModal,
  BitsyModalGiveaway,
  SignUpForm,
  SignInForm,
  CouponAndGiftCardForm,
  Cart,
  Testimonials,
  HomepageManifesto,
  AsSeenIn,
  GetTheBox,
  HowItWorks,
  SocialProof,
  WteFooter,
  PnmagFooter,
  Footer,
  AdminNav,
  CompanyNav,
  PartnershipNavigation,
  Navigation,
  CancelSurvey,
  PastSubs,
  Guarantee,
  SelectSubscriptionBoxes,
  SubscriptionDetailsForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  CorporateGiftForm,
  ContactForm,
  PartnershipsForm,
  VerticalTimeline,
  SearchForm,
  ShopPageMenu,
  BitsyMobileMenu,
  BumpMenu,
  BitsyMenu,
  RegistryMenu,
  UpdateCCForm,
  TopBanner,
  CartTotal,
  Trimesters,
  FAQAccordion,
  BreastPumpFooter,
  ReferralSharing,
  ReferralSharingCopyLink,
  ConfirmPasswordForm,
  PregnancyAnnouncementIdeas,
  TypeaheadToggleButton,
  InsuranceCardUpload,
};

import React, { useEffect, useState } from 'react';
import { Row, Col, FormCheck } from 'react-bootstrap';

import BitsyApollo from '../../services/BitsyApollo';
import BBAnalytics from '../../services/BBAnalytics';

import './style.scss';

export default function ShippingInsurance(props) {
  const [shippingInsurance, setShippingInsurance] = useState({});
  const [hasSubscription, setHasSubscription] = useState(false);

  useEffect(() => {
    const addOrRemoveShippingInsurance = () => {
      const { cartId, isShippingInsuranceSelected } = props;
      const dataObj = {
        cartId,
        isAddingInsurance: isShippingInsuranceSelected,
      };

      BitsyApollo.addOrRemoveShippingInsuranceForCart(dataObj).then(
        async (res) => {
          if (res && res.shippingInsurance && res.shippingInsurance.cost) {
            setShippingInsurance(res.shippingInsurance);
          }
          const setState = true;
          const cart = await props.getAndSetCart(setState);
          await BBAnalytics.updatedCartEvent(cart);
        },
      );
    };

    const setSubscriptionInfo = () => {
      const hasSub = !!props.subscription;
      setHasSubscription(hasSub, props.subscription);
    };

    addOrRemoveShippingInsurance();
    setSubscriptionInfo();
    // eslint-disable-next-line
  }, [props.isShippingInsuranceSelected]);

  return (
    <Row className='shipping-insurance-container'>
      <Col>
        <FormCheck
          aria-label={`Add shipping protection for ${shippingInsurance.cost}`}
        >
          <FormCheck.Label>
            <FormCheck.Input
              checked={props.isShippingInsuranceSelected}
              onChange={props.onShippingInsuranceChange}
              name='isShippingInsuranceSelected'
              isStatic={false}
              id='shippingInsuranceTitle'
            />
            Yes, I would like to protect my{' '}
            {hasSubscription ? 'subscription' : 'purchase'} with Shipping
            Protection for just ${shippingInsurance.cost}
            {hasSubscription ? ' with each box' : ''}.
          </FormCheck.Label>
        </FormCheck>
      </Col>
    </Row>
  );
}

import Loadable from 'react-loadable';
import AppLoader from '../../components/loading';

const Home = Loadable({
  loader: () => import('../home'),
  loading: AppLoader,
});

const MyAccount = Loadable({
  loader: () => import('../my-account'),
  loading: AppLoader,
});

const SubscriptionDetails = Loadable({
  loader: () => import('../subscription-details'),
  loading: AppLoader,
});

const PaymentDetails = Loadable({
  loader: () => import('../payment-details'),
  loading: AppLoader,
});

const DueDateCalculator = Loadable({
  loader: () => import('../due-date-calculator'),
  loading: AppLoader,
});

const WeekByWeek = Loadable({
  loader: () => import('../week-by-week'),
  loading: AppLoader,
});

const ThankYou = Loadable({
  loader: () => import('../checkout/thank-you'),
  loading: AppLoader,
});

const StartMembership = Loadable({
  loader: () => import('../checkout-single-page/start-membership'),
  loading: AppLoader,
});

const WelcomeFlow = Loadable({
  loader: () => import('../checkout-single-page/welcome-flow'),
  loading: AppLoader,
});

const Shop = Loadable({
  loader: () => import('../shop'),
  loading: AppLoader,
});

const Faq = Loadable({
  loader: () => import('../faq'),
  loading: AppLoader,
});

const About = Loadable({
  loader: () => import('../about'),
  loading: AppLoader,
});

const BumpFund = Loadable({
  loader: () => import('../bump-fund'),
  loading: AppLoader,
});

const LoyaltyProgram = Loadable({
  loader: () => import('../loyalty-program'),
  loading: AppLoader,
});

const Contact = Loadable({
  loader: () => import('../contact'),
  loading: AppLoader,
});

const Partnerships = Loadable({
  loader: () => import('../partnerships'),
  loading: AppLoader,
});

const SignIn = Loadable({
  loader: () => import('../signin'),
  loading: AppLoader,
});

const ForgotPassword = Loadable({
  loader: () => import('../forgot-password'),
  loading: AppLoader,
});

const ResetPassword = Loadable({
  loader: () => import('../reset-password'),
  loading: AppLoader,
});

const BreastPump = Loadable({
  loader: () => import('../breast-pump'),
  loading: AppLoader,
});

const Careers = Loadable({
  loader: () => import('../careers'),
  loading: AppLoader,
});

const TermsOfService = Loadable({
  loader: () => import('../terms-of-service'),
  loading: AppLoader,
});

const PrivacyPolicy = Loadable({
  loader: () => import('../privacy-policy'),
  loading: AppLoader,
});

const SecurityDisclosure = Loadable({
  loader: () => import('../security-disclosure'),
  loading: AppLoader,
});

const ElectronicRecordsAndSignatureDisclosure = Loadable({
  loader: () => import('../electronic-records-and-signature-disclosure'),
  loading: AppLoader,
});

const CorpGifting = Loadable({
  loader: () => import('../corporate-gifting'),
  loading: AppLoader,
});

const Press = Loadable({
  loader: () => import('../press'),
  loading: AppLoader,
});

const ErrorPage = Loadable({
  loader: () => import('../error'),
  loading: AppLoader,
});

const Page404 = Loadable({
  loader: () => import('../page-404'),
  loading: AppLoader,
});

const Page403 = Loadable({
  loader: () => import('../page-403'),
  loading: AppLoader,
});

const Product = Loadable({
  loader: () => import('../product'),
  loading: AppLoader,
});

const Review = Loadable({
  loader: () => import('../review'),
  loading: AppLoader,
});

const SelectSubscription = Loadable({
  loader: () => import('../select-subscription'),
  loading: AppLoader,
});

const Checkout = Loadable({
  loader: () => import('../checkout'),
  loading: AppLoader,
});

const AdminPortalEntry = Loadable({
  loader: () => import('../admin-portal-entry'),
  loading: AppLoader,
});

const ShopRedirect = Loadable({
  loader: () => import('../shop-redirect'),
  loading: AppLoader,
});

const BlogRedirect = Loadable({
  loader: () => import('../blogredirect'),
  loading: AppLoader,
});

const Blog = Loadable({
  loader: () => import('../blog'),
  loading: AppLoader,
});

const BreastPumpBrowse = Loadable({
  loader: () => import('../breast-pump/breast-pump-browse'),
  loading: AppLoader,
});

const BreastPumpPostPurchase = Loadable({
  loader: () => import('../breast-pump/breast-pump-post-purchase'),
  loading: AppLoader,
});

const BreastPumpThankYou = Loadable({
  loader: () => import('../breast-pump/thank-you'),
  loading: AppLoader,
});

const CorporatePortal = Loadable({
  loader: () => import('../corporate-portal'),
  loading: AppLoader,
});

const CorporateWSP = Loadable({
  loader: () => import('../corporate-wsp'),
  loading: AppLoader,
});

const CorporateVerification = Loadable({
  loader: () => import('../corporate-verification'),
  loading: AppLoader,
});

const PrenatalVitamins = Loadable({
  loader: () => import('../prenatal-vitamins'),
  loading: AppLoader,
});

const CustomizeSubscription = Loadable({
  loader: () => import('../customize-subscription'),
  loading: AppLoader,
});

const CustomizeSubscriptionLandingPage = Loadable({
  loader: () => import('../customize-subscription-landing-page'),
  loading: AppLoader,
});

const ViewRegistry = Loadable({
  loader: () => import('../registry/view-registry'),
  loading: AppLoader,
});

const FindRegistry = Loadable({
  loader: () => import('../registry/find-registry'),
  loading: AppLoader,
});

const ManageRegistry = Loadable({
  loader: () => import('../registry/manage-registry'),
  loading: AppLoader,
});

const CreateRegistry = Loadable({
  loader: () => import('../registry/create-registry'),
  loading: AppLoader,
});

const Covid19 = Loadable({
  loader: () => import('../covid19'),
  loading: AppLoader,
});

const PatientGrievance = Loadable({
  loader: () => import('../patient-grievance'),
  loading: AppLoader,
});

const PatientRights = Loadable({
  loader: () => import('../patient-rights'),
  loading: AppLoader,
});

const PrivacyNotice = Loadable({
  loader: () => import('../privacy-notice'),
  loading: AppLoader,
});

const Warranty = Loadable({
  loader: () => import('../warranty'),
  loading: AppLoader,
});

const AssignmentOfBenefits = Loadable({
  loader: () => import('../assignment-of-benefits'),
  loading: AppLoader,
});

const MyOrder = Loadable({
  loader: () => import('../my-order'),
  loading: AppLoader,
});

const EditPayment = Loadable({
  loader: () => import('../edit-payment'),
  loading: AppLoader,
});

const Giveaway = Loadable({
  loader: () => import('../giveaway'),
  loading: AppLoader,
});

const ReferAFriend = Loadable({
  loader: () => import('../refer-a-friend'),
  loading: AppLoader,
});

const Extensions = Loadable({
  loader: () => import('../extensions'),
  loading: AppLoader,
});

const CustomerReviews = Loadable({
  loader: () => import('../customer-reviews'),
  loading: AppLoader,
});

const Offers = Loadable({
  loader: () => import('../offers'),
  loading: AppLoader,
});

const GiveawayTerms = Loadable({
  loader: () => import('../giveaway-terms'),
  loading: AppLoader,
});

const ImageEditor = Loadable({
  loader: () => import('../image-editor'),
  loading: AppLoader,
});

const BabyNames = Loadable({
  loader: () => import('../baby-names'),
  loading: AppLoader,
});

export {
  Home,
  MyAccount,
  SubscriptionDetails,
  PaymentDetails,
  DueDateCalculator,
  WeekByWeek,
  ThankYou,
  Shop,
  Faq,
  About,
  BumpFund,
  LoyaltyProgram,
  Contact,
  Partnerships,
  SignIn,
  ForgotPassword,
  ResetPassword,
  BreastPump,
  Careers,
  TermsOfService,
  PrivacyPolicy,
  SecurityDisclosure,
  ElectronicRecordsAndSignatureDisclosure,
  CorpGifting,
  Press,
  ErrorPage,
  Page404,
  Page403,
  Product,
  Review,
  SelectSubscription,
  Checkout,
  AdminPortalEntry,
  ShopRedirect,
  BlogRedirect,
  Blog,
  BreastPumpBrowse,
  BreastPumpPostPurchase,
  BreastPumpThankYou,
  CorporatePortal,
  CorporateWSP,
  CorporateVerification,
  PrenatalVitamins,
  CustomizeSubscription,
  CustomizeSubscriptionLandingPage,
  ViewRegistry,
  FindRegistry,
  ManageRegistry,
  CreateRegistry,
  Covid19,
  PatientGrievance,
  PatientRights,
  PrivacyNotice,
  Warranty,
  AssignmentOfBenefits,
  MyOrder,
  EditPayment,
  Giveaway,
  ReferAFriend,
  Extensions,
  CustomerReviews,
  Offers,
  GiveawayTerms,
  ImageEditor,
  BabyNames,
  StartMembership,
  WelcomeFlow,
};
